<template lang="html">


  <div class="usluga">
    <div class="headUsluga">


  <router-link class="navbar-brand" to="/ksusluge">Back </router-link>
    <h3>Talent Management</h3>
    <p>Talenti su oni ljudi koji doprinose razvoju Kompanije i dostizanju Kompanijskih ciljeva i na taj način stvaraju dodatne vrednosti za Kompaniju i imaju snažan uticaj na poslovanje organizacije. Upravljanje talentima je proces koji se odvija kroz nekoliko faza, koje podrazumevaju privlačenje najtalentovanijih zaposlenih, prepoznavanje talenata u Kompaniji, definisanje načina za razvoj potencijala Talenata, usklađivanje potencijala Talenata sa ciljevima Kompanije, formiranje korporativne kulture koja podstiče performans zaposlenih i motivaciju Talenata.</p>
    <h5>Potrebna Vam je ova usluga? Popunite polja ispod:</h5>
        </div>
    <div class="card-body">


         <div class="form-group">
           <label for="numberOfEmployees">Broj zaposlenih za učešće u Talent programu (Procena internih timova)</label>
           <input
             class="form-control"
             type="text"
             placeholder=""
             name="numberOfEmployees"
             v-model="numberOfEmployees"
             id="numberOfEmployees"
             >
         </div>
        <div class="form-group">
          <label for="companyName">Naziv Kompanije<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="companyName"
            v-model="companyName"
            id="companyName"
            >
        </div>
        <div class="form-group">
          <label for="contactName">Ime i Prezime Kontakt Osobe<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="contactName"
            v-model="contactName"
            id="contactName"
            >
        </div>
        <div class="form-group">
          <label for="contactNumber">Kontakt telefon<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="contactNumber"
            v-model="contactNumber"
            id="contactNumber"
            >
        </div>
        <div class="form-group">
          <label for="email">E-mail<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="email"
            v-model="email"
            id="email"
            >
        </div>



        <div class="validation">
          {{validation}}
        </div>
        <button class="btn btn-primary" @Click="submit">Posalji Formular</button>
        &nbsp;&nbsp;&nbsp;&nbsp;



  </div>

  </div>


</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
export default {

  name: 'TalentMenagement',
  components: {

  },
  data(){
    return{
      companyName:"",
  contactName:"",
  contactNumber:"",
  email:"",
  numberOfEmployees :"",
  validation:"",
  date:"",
    }
  },
  methods:{
  ...mapActions(['submitServiceAplication']),
  submit(){

    this.validation="";

    if(this.companyName==""){
      this.validation="Unesite naziv kompanije."
    }
    if(this.contactName==""){
      this.validation="Unesite ime i prezime kontakt osobe."
    }
    if(this.email==""){
      this.validation="Unesite email."
    }
    if(this.contactNumber==""){
      this.validation="Unesite kontakt telefon."
    }
    const d = new Date();
    let day = d.getDate();
    let month = d.getMonth()+1;
    let year = d.getFullYear();
    this.date= "Datum: "+day+"."+month+"."+year+".";

    let text = [];
    text.push("Talent Management");
    text.push(this.date);
    text.push("Naziv Kompanije: "+this.companyName);
    text.push("Ime i Prezime Kontakt Osobe: "+this.contactName);
    text.push("Email: "+this.email);
    text.push("Kontakt telefon: "+this.contactNumber  );
    text.push("Broj zaposlenih za učešće u Talent programu (Procena internih timova): "+this.numberOfEmployees);

    let data ={
      formular:text
    }

    if(this.validation==""){
    this.submitServiceAplication(data);
    this.validation="Uspesno poslat formular."
    }
  },
},
  beforeCreate: function() {
        document.body.className = 'usluge';
  }
}
</script>

<style lang="css" scoped>
.usluga{
  position: absolute;
  top: auto;
  left: 10%;
  right: 10%;
  background-color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin-top: 15px;

}
.headUsluga{
  background-color: #77bbee;
  padding: 10px;
  border-top-left-radius:  10px;
  border-top-right-radius:  10px;
}
.card-body{
  background-color: #bbffff;
  border-bottom-left-radius:  10px;
  border-bottom-right-radius:  10px;
}
.validation{
  margin-top: 5px;
  background-color: #00aaaa;
}
</style>
